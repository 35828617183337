.suite__sidebar {
    // border: 1px solid black
    position: fixed;
    width: 19%;
    min-height: 90vh;
    top: 10px;
    bottom: 10px;
    left: 10px;
    background-color: $white;
    color: $dark-gray;
    overflow: hidden auto;
    border-radius: 20px;
    padding: 8px;
    .suite__sidebar-navbar {
      .logo {
        // display: flex
        // justify-content: center
        // align-items: center
        margin-bottom: 60px;
        margin-right: 6px;
        text-align: center;
        img {
          width: 195px; }
        .divider-sidebar {
          display: flex;
          height: 1px;
          width: 100%;
          background: linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, rgb(224, 225, 226) 49.52%, rgba(224, 225, 226, 0) 100%);
          margin-top: 26px;
          margin-bottom: 26px; } }
      strong {
        padding: 0 20px;
        text-transform: uppercase; }
      .sidebar-menu {
        margin-top: 20px;
        padding: 0 8px;
        a {
          text-decoration: none;
          margin: 2px 0;
          // transition: cubic-bezier(0, 0, 0, 1.46)-in .3s
          position: relative;
          li {
            list-style: none;
            color: $admin-colo-gray-400;
            padding: 15px 20px;
            border-radius: 8px;
            display: block;
            // transition: cubic-bezier(0, 0, 0, 1.46)-in .3s
            font-weight: 600;
            display: flex;
            align-items: center;
            .content-icon {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              border-radius: 8px;
              height: 30px;
              width: 30px;
              margin-inline-end: 12px;
              transition: all 0.2s linear 0s;
              i {
                font-size: 12px;
                --fa-animation-duration: 2s; } } }
          &.active {
            li {
              background-color: $white;
              box-shadow: rgba(0, 0, 0, 0.05) 0px 7px 23px;
              color: $dark-gray;
              transition: all 0.2s linear 0s;
              .content-icon {
                background: $background-blue;
                color: $white; }
              &:hover {
                color: $dark-gray; } } }
          &:hover {
            li {
              // background-color: $white
              // box-shadow: rgba(0, 0, 0, 0.05) 0px 7px 23px
              color: $background-blue; } } } }
      .logout {
        position: absolute;
        // bottom: 20px
        width: 93%;
        li {
          list-style: none;
          margin: 2px 0;
          border-radius: 8px;
          transition: cubic-bezier(0, 0, 0, 1.46)-in .3s;
          a {
            text-decoration: none;
            color: $background-blue;
            padding: 15px 20px;
            display: block;
            transition: cubic-bezier(0, 0, 0, 1.46)-in .3s;
            i {
              margin-right: 10px; } }
          &:hover {
            background-color: $background-blue;
            a {
              color: $white; } } } } } }

.admin__navbar {
  border-bottom: 1px solid #f1f3f9;
  height: 100px;
  position: sticky;
  top: 10px;
  width: 100%;
  margin-bottom: 25px;
  border-radius: 20px;
  padding: 8px 16px;
  max-height: 75px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 7px 23px;
  background: linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%);
  display: flex;
  align-items: center;
  .content-breadcrumb {
    .nav-breadcrumb {
      ol.list-breadcrumb {
        margin: 0;
        padding: 0;
        li.breadcrumb-item {
          display: inline-flex;
          -webkit-box-align: center;
          align-items: center;
          color: $dark-gray;
          a {
            color: $admin-colo-gray-400;
            text-decoration: none;
            transition: .3s all;
            &:hover {
              color: $dark-gray;
              text-decoration: underline; } }
          &::before {
            color: $admin-colo-gray-400; }
          // span
 } } }          //   margin-inline: 0.5rem
    .current-page-link {
      // cursor: pointer;
      text-decoration: none;
      outline: transparent solid 2px;
      outline-offset: 2px;
      color: $dark-gray;
      background: inherit;
      border-radius: inherit;
      font-weight: 700; } }
  .content-navbar {
    display: flex;
    width: 100%;
    .content-avatar {
      margin-inline-start: auto;
      .dropdownProfileMenu {
        img {
          width: 50px;
          border-radius: 50%;
          margin-right: 20px; } } } } }

.admin__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  margin-bottom: 15px;
  color: $admin-colo-gray-400;
  .copyright {
    p {
      margin: 0;
      a {
        text-decoration: none;
        color: $background-blue; } } }
  .powered {
    display: flex;
    align-items: baseline;
    span.text {
      margin-right: 15px;
      font-style: italic;
      strong {
        font-style: initial; } }
    span.logo-footer {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      p {
        margin: 0; }
      img {
        width: 70px;
        object-fit: contain; } } } }
