// Colors
$primary: #33247e;
$secondary: #fddc05;
$dark-gray: #333;
$light-gray: #999;
$background: #f4f6fa;
$background-blue: #3182ce;
$admin-colo-gray-400: #A0AEC0;
$admin-colo-gray-200: #E2E8F0;

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;
