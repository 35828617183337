.admin__forms {
    // border: 1px solid red
    .form-label {
        font-size: 14px; }
    .form-control, .form-select {
        border: 1px solid $admin-colo-gray-400;
        border-radius: 10px;
        transition: all .3s;
        &:focus {
            outline: 0;
            box-shadow: none;
            border: 1px solid $background-blue; } }
    .form-button {
        text-align: right;
        margin-top: 15px;
        .btn {
            border: 1px solid $background-blue;
            border-radius: 8px;
            padding: 8px 20px;
            background-color: $background-blue;
            color: $white;
            text-transform: uppercase;
            font-size: 14px;
            transition: all .3s;
            &:hover {
                background-color: transparent;
                color: $background-blue; }
            &:focus {
                outline: 0;
                box-shadow: none; } } }
    .form-button-add {
        text-align: left;
        margin-top: 15px;
        .btn {
            border-radius: 8px;
            background-color: #3182ce;
            color: #fff;
            text-transform: uppercase;
            font-size: 10px;
            transition: all .3s;
            margin-left: 5px;
            &:hover {
                background-color: transparent;
                color: $background-blue; }
            &:focus {
                outline: 0;
                box-shadow: none; } } } }
