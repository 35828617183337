.admin__table {
  border-radius: 20px;
  background-color: $white;
  padding: 22px 22px 0px;
  .heading {
    padding: 6px 0px 22px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .functions {
      display: flex;
      align-items: center;
      input {
        margin-right: 20px;
        border: 1px solid $admin-colo-gray-400;
        border-radius: 8px;
        padding: 5px 10px;
        font-size: 12px;
        transition: all .2s;
        &:focus {
          border: 1px solid $background-blue;
          outline: 0; } }
      button {
        border: 1px solid $background-blue;
        border-radius: 8px;
        padding: 6px 20px;
        background-color: $background-blue;
        color: $white;
        text-transform: uppercase;
        font-size: 10px;
        transition: all .3s;
        &:hover {
          background-color: transparent;
          color: $background-blue; } } } }
  .admin-table {
    table.table {
      border-collapse: collapse;
      thead {
        tr.table-row-head {
          margin-top: 0.8rem;
          margin-bottom: 0.8rem;
          padding-left: 0px;
          th.first-head-item, .head-item, .last-head-item {
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            text-align: start;
            padding-inline-start: 1.5rem;
            padding-inline-end: 1.5rem;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            line-height: 1rem;
            font-size: 0.75rem;
            color: $admin-colo-gray-400;
            // border-bottom-width: medium
            // border-bottom-style: double
            border-color: $admin-colo-gray-400; }
          th.first-head-item {
            padding-left: 0px; } } }
      tbody {
        border: 0;
        tr.table-row-body {
          td.first-body-item, .body-item {
            text-align: start;
            padding-inline-start: 1.5rem;
            padding-inline-end: 1.5rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            line-height: 1.25rem;
            border-color: $admin-colo-gray-200;
            vertical-align: middle;
            .info-column {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              flex-wrap: nowrap;
              padding-top: 0.8rem;
              padding-bottom: 0.8rem;
              min-width: 100%;
              span.table-avatar {
                border-radius: 12px;
                display: inline-flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                text-align: center;
                text-transform: uppercase;
                font-weight: 500;
                position: relative;
                flex-shrink: 0;
                background: $admin-colo-gray-400;
                color: $admin-colo-gray-200;
                border-color: $white;
                vertical-align: top;
                width: 50px;
                height: 3rem;
                font-size: calc(1.2rem);
                margin-inline-end: 18px;
                img.avatar-img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 12px; } } }
            .info {
              display: flex;
              flex-direction: column;
              p {
                margin: 0; }
              .text-sup {
                font-size: 1rem;
                color: #1f2733;
                font-weight: 700;
                min-width: 100%; }
              .text-inf {
                font-size: 0.875rem;
                color: $admin-colo-gray-400;
                font-weight: 400;
                a {
                  color: $admin-colo-gray-400; } }
              .table-badge {
                display: inline-block;
                white-space: nowrap;
                vertical-align: middle;
                padding-inline-start: 0.25rem;
                padding-inline-end: 0.25rem;
                text-transform: capitalize;
                font-size: 16px;
                border-radius: 8px;
                font-weight: 700;
                background-color: $admin-colo-gray-400;
                color: $white;
                padding: 3px 10px 5px;
                text-align: center;
                &.activo {
                  background-color: #48BB78; } } }
            .info-icon {
              display: flex;
              align-items: center;
              i {
                color: #1f2733;
                font-weight: 700;
                font-size: 18px; }
              .fa-whatsapp {
                color: #25D366; }
              p {
                margin: 0 0 0 8px;
                a {
                  color: #1f2733; } } }
            .more-button {
              display: inline-block;
              border: 0;
              padding: 15px;
              background-color: transparent;
              i {
                font-size: 20px;
                color: $admin-colo-gray-400;
                transition: all .2s; }
              &:hover {
                i {
                  color: unset; } } } }
          .first-body-item {
            min-width: 250px;
            padding-left: 0 !important; }
          &:last-child {
            td.first-body-item, .body-item {
              border-color: transparent; } } } } } } }

