.admin__headquarters, .admin__team, .admin__inventory, .admin__thirds {
    &.admin__form {
        border-radius: 20px;
        background-color: #fff;
        padding: 22px 22px 22px;
        .heading {
            padding: 6px 0px 22px;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: space-between;
            button {
                border: 1px solid $background-blue;
                border-radius: 8px;
                padding: 6px 20px;
                background-color: transparent;
                color: $background-blue;
                // text-transform: uppercase
                font-size: 12px;
                transition: all .3s;
                &:hover {
                    background-color: $background-blue;
                    color: $white; } } } }
    .content-form {
        width: 80%;
        margin: 20px auto 54px;
        padding: 32px 42px;
        border: 1px solid $admin-colo-gray-400;
        border-radius: 20px; } }

.modal-alert {
    border-radius: 20px !important;
    padding: 10px 0 45px !important; }
