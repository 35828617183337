.suite__main {
  background-color: $background;
  height: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  .divider-background {
      min-height: 40vh;
      position: absolute;
      width: 100%;
      top: 0;
      background-color: $background-blue;
      z-index: -1;
      &::before {
          position: absolute;
          content: '';
          background-color: $background-blue;
          width: 100%;
          height: 620px;
          bottom: -333px;
          left: -98px;
          transform: skew(283deg); } }
  .main-container {
    flex-direction: column;
    flex: 1 1;
    margin-left: 22%;
    margin-right: 10px;
    margin-top: 10px;
    min-height: 100vh;
    border-radius: 20px; } }
