.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid;
  border-radius: 3px;
  padding-left: 2rem;
  padding-right: 2rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  &:focus {
    outline: none; } }

.btn-secondary {
  background-color: $background-blue;
  border-color: $background-blue;
  color: #333;
  font-weight: 700;
  &:hover {
    border-color: $background-blue;
    background-color: $background-blue; }
  &:disabled {
    background-color: lighten($color: $background-blue, $amount: 15);
    &:hover {
      border-color: lighten($color: $background-blue, $amount: 15);
      background-color: lighten($color: $background-blue, $amount: 15); } } }

.btn-block {
  display: block;
  width: 100%; }


.google-btn {
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  height: 38px;
  background-color: $google-blue;
  border-radius: 3px;
  transition: box-shadow .3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .google-icon-wrapper {
    margin-top: 0;
    margin-left: 0;
    width: 40px;
    height: 38px;
    border-radius: 3px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: $white;
    .google-icon {
      position: absolute;
      margin-top: 10px;
      margin-left: -11px;
      width: 18px;
      height: 18px; } }
  .btn-text {
    margin: 0 25% 0 0;
    color: $white;
    font-size: 14px;
    letter-spacing: 0.2px; }
  &:hover {
    background-color: darken($color: $google-blue, $amount: 15); }
  &:active {
    background: $button-active-blue; } }


