* {}
//   font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
//font-family: 'PT Sans', sans-serif;
//margin: 0
//box-sizing: border-box

html, body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden; }

main {
  width: 100%; }

img {
  width: 100%; }

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    font-weight: 600; }

// Margin top 1
.mt-1 {
  margin-top: 5px; }

// Margin top 2
.mt-2 {
  margin-top: 8px; }

// Margin top 4
.mt-4 {
  margin-top: 24px !important; }

// Margin top 5
.mt-5 {
  margin-top: 20px; }

// Margin bottom 1
.mb-1 {
  margin-bottom: 5px; }

// Margin bottom 2
.mb-2 {
  margin-bottom: 8px; }

// Margin bottom 4
.mb-4 {
  margin-bottom: 24px !important; }

// Margin bottom 5
.mb-5 {
  margin-bottom: 20px; }

// Padding top 4
.pt-4 {
  padding-top: 24px !important; }

// Padding bottom 4
.pb-4 {
  padding-bottom: 24px !important; }

// Padding inzquierda + derecha
.px-3 {
  padding-left: 16px !important;
  padding-right: 16px !important; }

.h-100 {
  min-height: 100vh; }

.pointer {
  cursor: pointer; }

.text-center {
  text-align: center !important; }

.container-flex {
  display: flex !important; }

.align-items-center {
  align-items: center !important; }

.justify-content-center {
  justify-content: center !important; }

.flex-column {
  flex-direction: column  !important; }

.col-4 {
  flex: 33.333333%;
  max-width: 33.333333%; }

.col-8 {
  max-width: 66.666667%;
  flex: 0 0 66.666667%; }
