.auth__main {
  background-color: transparent;
  min-height: 100vh;
  height: 100%;
  color: $dark-gray;
  line-height: 1.3;
  border: 1px solid;
  .content-login {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    padding: 0 20px;
    .logo-login {
      width: auto;
      height: 55px;
      vertical-align: middle; }
    h1 {
      font-size: 1.8em;
      font-weight: 700;
      line-height: 1.2;
      margin-top: 0;
      margin-bottom: 0.5rem; }
    p {
      font-size: 14px;
      margin: 0.35rem 0;
      font-weight: 300;
      color: #484848; }
    .create-account {
      font-weight: 600; }
    .login-form {
      margin-top: 30px;
      .form-group {
        margin-bottom: 16px;
        text-align: left;
        label {
          align-self: start;
          font-weight: 500;
          display: inline-block;
          margin-bottom: 0.5rem; } } } }
  .login-footer {
    color: #999;
    .logo-webkend {
      width: auto;
      height: 40px; } }
  .img-back {
    height: 100vh;
    object-fit: cover;
    vertical-align: middle; } }


.auth__alert-error {
  background-color: red;
  border-radius: 5px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding: 5px; }
